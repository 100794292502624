/* Global Styles for the Whole App */

/* Enable scrolling but hide the scrollbar */
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden; /* Disable horizontal scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Hide scrollbar for modern browsers */
body::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, Edge */
}

body {
  -ms-overflow-style: none; /* Hide scrollbar for Internet Explorer 10+ */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

html {
  scroll-behavior: smooth;
}

/* Ensure images and videos are responsive */
img, video {
  max-width: 100%; /* Prevent media from overflowing horizontally */
  height: auto; /* Maintain aspect ratio */
}

/* Code block styles (if needed) */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

