.about-section {
    background-color: #385170; /* Dark blue background */
    color: #f2f2f2; /* Light text color */
    padding: 3rem 5%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: left;
    gap: 1rem;
}

.about-section .heading {
    font-size: 1.8rem;
    font-weight: bold;
    text-align: left;
}

.about-section .divider {
    width: 90%;
    height: 2px;
    background-color: #f2f2f2; /* Light divider line */
    margin: 1rem 0;

}

.about-section .text-box {
    max-width: 700px;
    color: #f2f2f2;
    text-align: left;
}

.about-section .text {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 1rem;
    text-align: left;
}

.clickable {
    color: #ff7f50; /* Coral/orange color */
    cursor: pointer;
    font-weight: bold; /* Make the text bold */
    text-decoration: none; /* Remove underline */
}

.clickable:hover {
    color: #ff4500; /* Darker orange on hover */
    text-decoration: none; /* Ensure no underline on hover */
}



/* Styles for Tablet and Desktop Screens */
@media (min-width: 768px) {
    .about-section {
        text-align: center; /* Center-align text for larger screens */
        align-items: center; /* Center content horizontally */
    }

    .about-section .heading {
        text-align: center; /* Center heading text */
        font-size: 3rem; /* Adjust font size for desktop */
    }

    .about-section .text-box {
        text-align: center; /* Center text content */
        font-size: 1.5rem; /* Slightly larger font size */
        line-height: 1.8; /* Increase line spacing for readability */
    }

    .about-section .text {
        font-size: 1.5rem;
        line-height: 1.6;
    }
    .about-section .divider {
        width: 40%; /* Make the divider smaller */
        margin: 1.5rem auto; /* Center the divider */
    }
}