/* Default Styles for Mobile */
.fifth-section {
    display: flex;
    flex-direction: column; /* Mobile-first layout */
    width: 100%;
}

.fifth-section .text-area {
    background-color: #f2f2f2; /* Light background for text */
    padding: 2rem 5%;
    display: flex;
    justify-content: center;
}

.fifth-section .text-box {
    max-width: 600px;
    text-align: left;
}

.fifth-section .heading {
    color: #062c30;
    font-size: 1.5rem;
    font-weight: bold;
}


.fifth-section .text {
    font-size: 1rem;
    line-height: 1.5;
    color: #062c30;
}

.fifth-section .image-area {
    background-color: #9fd3c7;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
    gap: 1rem;
}

.fifth-section .frame {
    border: 2px solid #f2f2f2;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    width: 40%;
    max-width: 300px;
}

.fifth-section .image {
    max-width: 100%;
    height: auto;
}

/* Styles for Tablet and Desktop Screens */
@media (min-width: 768px) {
    .fifth-section {
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: left; /* Left-aligned text */
    }

    .fifth-section .image-area {
        flex: 1; /* Equal width for image */
        display: flex;
        justify-content: center; /* Center image */
        padding: 2rem;
        margin-right: 2rem; /* Space between image and text */
    }

    .fifth-section .text-area {
        flex: 1; /* Equal width for text */
        padding: 2rem;
    }

    .fifth-section .text-box {
        max-width: 600px; /* Limit width for text */
    }

    .fifth-section .text-box .heading {
        font-size: 3rem; /* Larger heading */
        margin-bottom: 1rem; /* Spacing between heading and text */
    }

    .fifth-section .text-box .text {
        font-size: 1.5rem;
    }

    .fifth-section .frame {
        max-width: 700px; /* Larger frame size */
        border: 2px solid #f2f2f2;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        padding: 1rem;
    }

    .fifth-section .image {
        max-width: 100%; /* Image takes up full container */
        height: auto;
    }
}

